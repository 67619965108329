import axios, { AxiosResponse } from 'axios'
import { BACKEND_URL } from '../constants';
import { Post, PostReadResponse, PostResponse, TagResponse } from '../types';
import { error } from 'console';

const baseUrl = `${BACKEND_URL}/api`;

const client = axios.create({
    baseURL: baseUrl
});


// get the [count] most recent posts from our ghost webserver
function getPosts(count: number): Promise<PostResponse> {
    const request: Promise<AxiosResponse> = client.get(`/posts/browse/${count}`);
    return request.then(response => response.data);
}

// gets the [count] most recent posts, offset by given page number
function getPostsPaginated(count: number, page: number): Promise<PostResponse> {
    const request: Promise<AxiosResponse> = client.get(`/posts/browse/${page}/${count}`);
    return request.then(response => response.data);
}

// gets all posts matching given [tag]
function getPostsByTag(tags: string[]): Promise<PostResponse> {
    const request: Promise<AxiosResponse> = client.get(`/posts/tag/${tags.join('')}`);
    return request.then(response => response.data);
}

// get a single post matching the given [slug]
function getPostBySlug(slug: string): Promise<PostReadResponse> {
    const request: Promise<AxiosResponse> = client.get(`/posts/read/${slug}`);
    return request.then(response => response.data);
}

// gets [count] most recent posts matching query. depending on provided values [search] and [tags], creates filter which
// posts must match. call is paginated according to provided [page] value. 
function getPostsBySearch(count: number, page: number, search: string | null, tags: string[] | null): Promise<PostResponse> {
    let params = {search: search, tags: tags};

    const request: Promise<AxiosResponse> = axios.get(`${baseUrl}/posts/search/${count}/${page}`, { "params": params })
    return request.then(response => response.data)
}

function getTags(): Promise<TagResponse> {
    const request: Promise<AxiosResponse> = axios.get(`${baseUrl}/tags`)
    return request.then(response => response.data)
}

export default { getPosts, getPostsPaginated, getPostsByTag, getPostBySlug, getPostsBySearch, getTags }