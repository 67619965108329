import React, { useState, useEffect, lazy } from 'react'
import postService from '../services/posts'
import { Post, ParsedPost, PostReadResponse } from '../types'
import { useParams } from 'react-router-dom'
import parse, { attributesToProps, DOMNode } from 'html-react-parser';

import ImageContainer from './ImageContainer';
import LoadingComponent from './util/LoadingComponent';
import { writeDateString } from '../util'
import '../styles/post.css'
import { useDocumentTitle } from '../hooks/useDocumentTitle';




const PostPage: React.FC = (): JSX.Element => {

  const [post, setPost] = useState<ParsedPost>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useDocumentTitle(post?.title);

  const { slug } = useParams<{ slug: string }>();
  const errHtml: string = '<p> post not found ;( </p>'


  const replace = (domNode: DOMNode) => {
    if(domNode.type === 'tag' && domNode.name === 'iframe'){
      const props = attributesToProps(domNode.attribs);
      props['loading'] = 'lazy';
      props['referrerPolicy'] = 'no-referrer';
      props['allowFullScreen'] = false; // fullscreen is still allowed, but already specified in allow-list
      props['credentialless'] = "true";

      return <iframe {...props } />;
    }
    else if (domNode.type === 'script') {
      return <></>;
    } 
    else if (domNode.type === 'tag' && domNode.name === 'img') {
      const props = attributesToProps(domNode.attribs);
      props['loading'] = 'lazy'
      return <img {...props} />
    }
  }

  useEffect(() => {
    let subscribed = true;
    if (slug) {
      postService
        .getPostBySlug(slug)
        .then((response: PostReadResponse) => {
          if (subscribed) {
            setPost({
              slug: response.post.slug,
              id: response.post.id,
              title: response.post.title,
              custom_excerpt: response.post.custom_excerpt,
              feature_image: response.post.feature_image,
              feature_image_alt: response.post.feature_image_alt,
              published_at: response.post.published_at,
              html: parse(response.post.html || errHtml, {replace} ) 
            });
            setLoaded(true);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoaded(true);
        })
    }

    return () => {
      subscribed = false;
    }
  }, [])

  return (
      <React.Fragment>
        {loaded ? (
          post ? (
          <div className='post-page'>
            <div>
              <div className='post-page-header'>
                <img src={post.feature_image} alt={post.feature_image_alt} />
                <h2>{post.title}</h2>
                <span className='post-page-header__date'>{writeDateString(new Date(post.published_at))}</span>
                {/* <span className='post-page-header__desc'>{post.custom_excerpt}</span> */}
                {/* <hr /> */}
              </div>
              <div className='post-page-content'>{post.html}</div>
            </div>
          </div>
          ) : 
          (<div className='post-container-error'>
            <h2>no post found :/</h2>
          </div>)
        )
        : 
        ( <LoadingComponent/> )}
    </React.Fragment>
  )

}

export default PostPage;
