
// import '../styles/contact.css'
import rymImage from '../img/rym.webp'
import lastfmImage from '../img/lastfm.webp'
import letterboxdImage from '../img/letterboxd.webp'
import goodreadsImage from '../img/goodreads.webp'

import ContactItem from './ContactItem'

import { useState } from 'react'
import { useDocumentTitle } from '../hooks/useDocumentTitle'

interface MessageElements extends HTMLFormControlsCollection {
  name: HTMLInputElement;
  email: HTMLInputElement;
  message: HTMLInputElement;
}

interface MessageForm extends HTMLFormElement {
  elements: MessageElements;
}


const ContactPage: React.FC = (): JSX.Element => {

  useDocumentTitle("Contact")

  const [emailInput, setEmailInput] = useState("");
  const [messageLength, setMessageLength] = useState(0);
  const [thanksMessage, setThanksMessage] = useState("");

  const maxLength = 500;

  function handleSubmit(e: React.FormEvent<MessageForm>) {

    e.preventDefault()
    const target = e.currentTarget.elements;

    if(target.email.value != "") {
      setMessageLength(0);
      e.currentTarget.reset();
      

      setThanksMessage("");
      return //ignore response
    }

    const data = { 
      name: target.name.value == "" ? "Anonymous" : target.name.value,
      message: target.message.value,
    };

    //console.log(data);

    setThanksMessage("This doesn't do anything yet :/");
    setMessageLength(0);
    e.currentTarget.reset();

  }

  return (
    <div className='contact'>
      <div className='contact-message'>
        <h3 className='contact-message-header'>If you want to contact me directly, send a message here!</h3>
        <form className='contact-message-form' onSubmit={handleSubmit}>
          <label style={{fontSize: "1.4rem", marginBottom: ".5rem", display: "flex", flexDirection: "column"}}>
            <span style={{marginBottom: ".4rem"}}>Name (optional)</span><input id='message-name' autoComplete="given-name" name='name' style={{marginBottom: "1rem", fontWeight: "300"}} required={false}/>
          </label>
          <input style={{display: "none"}} autoComplete='off' name='email' value="" onChange={(e) => setEmailInput(e.target.value)} required={false}/>
          <textarea id='message-input' autoComplete='off' maxLength={maxLength} name="message" placeholder="Your message here"  onInvalid={(e) => setThanksMessage("")} onChange={e => (setMessageLength(e.target.value.length))} required/>
          <p style={{marginInline: ".2rem", marginTop: ".5rem", marginBottom: ".2rem", fontSize: "1.4rem"}}>({messageLength}/{maxLength}) Characters used.</p>
          <p style={{marginInline: ".2rem", marginBlock: "0rem", fontSize: "1.4rem", fontWeight: "400"}}>{thanksMessage}</p>
          <button id='message-button' type='submit'>SEND</button>
        </form>
      </div>
      <h3 style={{marginTop: ".5rem"}} className='contact-email-header'>Or, if you prefer, you can send me an email <a id="email-link" href="mailto:web.smarkey@gmail.com">here!</a></h3>
      <h2 style={{marginTop: "6rem"}} className='contact-header'>You can find me online in these places:</h2>
      {/* <h3 className='contact-header'>Programming / Work Related</h3> */}
      <ContactItem url="https://github.com/monsarkey">
        <div className='contact-body'>
          <h3 className='contact-body__title'>Github</h3>
          <div className='contact-body__desc'>
            I use Github to manage code for personal projects I've been working on, including the source code for this website. A large portion of my repositories are private, 
            but I've made a few of them public if you want to check them out.
          </div>
        </div>
      </ContactItem>
      <ContactItem url="https://gitlab.com/monsarkey">
        <div className='contact-body'>
          <h3 className='contact-body__title'>Gitlab</h3>
          <div className='contact-body__desc'>
            I use this for contribution to group repositories, as I tend not to use it for personal projects. As of right now none of them are public, so there's not
            much of a reason to check it out. I may add some in the future.
          </div>
        </div>
      </ContactItem>
      {/* <h3 className='contact-header'>Music Related Social Media</h3> */}
      <ContactItem url="https://open.spotify.com/user/derpbaby?si=8584104dc7f94c86">
        <div className='contact-body'>
          <h3 className='contact-body__title'>Spotify</h3>
          <div className='contact-body__desc'>
            I use Spotify to do almost all of my music listening, with YouTube to fill in the gaps for its missing content. My account has quite a few playlists, including
            a giant one I use to sort the music I'd like to listen to later. Some of them are pretty neat, so check it out if you're interested.
          </div>
        </div>
      </ContactItem>
      <ContactItem url="https://rateyourmusic.com/~smarkey" img={rymImage} alt="circular RateYourMusic logo">
        <div className='contact-body'>
          <h3 className='contact-body__title'>RYM</h3>
          <div className='contact-body__desc'>
            I spend a lot of time on here- cataloging music I've listened to in the past, finding users with similar taste to follow for recommendations, and 
            browsing through RYM's genre listings. As of right now I only post ratings and not extensive written reviews. Check out my account if you're interested in finding 
            out the type of music I listen to.
          </div>
        </div>
      </ContactItem>
      <ContactItem url="https://www.last.fm/user/mon_sarkey" img={lastfmImage} alt="circular LastFM logo">
        <div className='contact-body'>
          <h3 className='contact-body__title'>LastFM</h3>
          <div className='contact-body__desc'>
            Here I actively track the music I've been listening to recently, and store logs of past listens to know how my taste has changed over time.
            This is mostly for personal use, but you can check out my profile if you want to know what I've been listening to. 
          </div>
        </div>
      </ContactItem>
      {/* <h3 className='contact-header'>Other Social Media</h3> */}
      <ContactItem url="https://letterboxd.com/mon_sarkey/" img={letterboxdImage} alt="circular Letterboxd logo">
        <div className='contact-body'>
          <h3 className='contact-body__title'>Letterboxd</h3>
          <div className='contact-body__desc'>
            This is where I log movies I've watched recently, and occasionally post written reviews sharing my thoughts on them. Check it out if you want
            to know more about the type of movies I'm into as of late.
          </div>
        </div>
      </ContactItem>
      <ContactItem url="https://www.goodreads.com/user/show/147468973-smarkey" img={goodreadsImage} alt="circular Goodreads logo">
        <div className='contact-body'>
          <h3 className='contact-body__title'>Goodreads</h3>
          <div className='contact-body__desc'>
            I mostly use Goodreads to catalog the books I'd like to read in the future, and rate them when I get around to doing so. My ratings aren't
            exactly consistent or sensical, but I might get around to fixing them at some point.
          </div>
        </div>
      </ContactItem>
    </div>
  )

}

export default ContactPage;