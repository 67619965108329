import '../styles/homepage.css'
import ImageContainer from './ImageContainer'
import PostContainer from './PostContainer'
import homeImg1 from '../img/cat_salt.webp'
import homeImg2 from '../img/bolivia_me.webp'
import homeImg3 from '../img/IMG_2203.webp'
import homeImg4 from '../img/mosaic.webp'

import patternSVG from '../img/splash_background_vector.svg'
import splashSVG from '../img/mobile_splash.svg'

import downloadIcon from '../img/download.webp'

import { useDocumentTitle } from '../hooks/useDocumentTitle'


const HomePage: React.FC = (): JSX.Element => {

  useDocumentTitle("Homepage")

  return (
    <div className='homepage'>
      <div className='homepage-splash' style={{ backgroundImage: `url(${patternSVG})` }}>
        <div className='homepage-splash-container'>
          <div className='homepage-splash-textbox'>
            <h1 className='homepage-splash-textbox__mobilemsg'>
              welcome to smarkey.me!
            </h1>
            <h1 className='homepage-splash-textbox__msg'>
              welcome to smarkey.me!
            </h1>
          </div>
          <div className='homepage-splash-vector' />
        </div>
      </div>
      <div className='homepage-intro'>
        <div className='homepage-intro__image'>
          <ImageContainer img={homeImg1} alt="proud cat posing in front of bolivian salt flats" size={200} />
        </div>
        <div className='homepage-intro__text'>
          <h1 id="headline-text">Hello!</h1>
          <p id="headline-subtext">
            Welcome to my website! In its current state, it's somewhere in between a professional portfolio and something more
            expressive. As I continue to use and expand this site, its purpose should become more well-defined. No matter what form
            it takes, this site will serve as my own personal corner of our increasingly impersonal web. Peer into it, and you'll
            find a small portion of me. That's the hope, at least.
          </p>
        </div>
      </div>
      <div className='homepage-intro'>
        <div className='homepage-intro__image'>
          <ImageContainer img={homeImg2} alt="picture of me smiling and giving a thumbs up" size={200} />
        </div>
        <div className='homepage-intro__text'>
          <h1 id="headline-text">Who am I?</h1>
          <p id="headline-subtext">
            My name is Sean, and I'm a 23 year old American with a wide range of interests: photography, history, music, film, literature,
            gaming, computer science, and geography, to name a few. I'm by no means an expert in any of these subjects, but I'm always interested
            in learning more. In no small part, this site exists to help me compile and share what I've learned over the years. Check back for the upcoming
            gallery and blog tabs, where I'll post about what I learn!
          </p>
        </div>
      </div>
      <div className='homepage-intro'>
        <div className='homepage-intro__image'>
          <ImageContainer img={homeImg4} alt="picture of an intricate grey and blue floor mosaic from pompeii" size={200} />
        </div>
        <div className='homepage-intro__text'>
          <h1 id="headline-text">What's next for this site?</h1>
          <div id="headline-subtext">
            <p>In its current state, I'm just working towards making a barebones personal site for launch, but I'm planning on implementing the following once we're stable:</p>
            <p id="intro-list-item">
              <span style={{ fontWeight: "bold" }}>A Gallery</span>
              <span style={{ fontSize: "1em" }}> to organize and display some of my favorite photos. </span>
            </p>
            <p id="intro-list-item">
              <span style={{ fontWeight: "bold" }}>A Blog</span>
              <span style={{ fontSize: "1em" }}> where I can post about what I've been getting into recently and work on my writing skills. Expect periodic essays on movies, music, etc. </span>
            </p>
            <p id="intro-list-item">
              <span style={{ fontWeight: "bold" }}>A Detailed Projects Page</span>
              <span style={{ fontSize: "1em" }}> that allows users to expand and read more about some of the projects I've been working on.  </span>
            </p>
          </div>
          {/* <h1 id="headline-bodytext">This is where</h1> */}
        </div>
      </div>
      <div className='homepage-intro'>
        <div className='homepage-intro__image'>
          <ImageContainer img={homeImg3} alt="wall art of a cartoon cat juggling pizzas" size={200} />
        </div>
        <div className='homepage-intro__text'>
          <h1 id="headline-text">Hire me!</h1>
          <p id="headline-subtext">I'm looking for work, and you can find my resume below!</p>
          <div className="resume__header">
            <span style={{ margin: "0px", paddingLeft: ".8rem", fontWeight: "bold", fontSize: "1.2em" }}>smarkey_resume.pdf</span>
            <a id="download-img" href="./smarkey_resume.pdf" download>
              <img src={downloadIcon} alt="download button" width="30px" height="30px" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )

}

export default HomePage;