import '../styles/blog.css'
import { Tag, TagResponse, Post, PostResponse, PostMetadata } from '../types'
import React, { useState, useEffect, forwardRef, ForwardRefExoticComponent, RefAttributes, useImperativeHandle, useRef, Suspense } from 'react'
import { LuSearch, LuArrowLeftToLine, LuArrowLeft, LuArrowRight, LuArrowRightToLine } from "react-icons/lu"
import postService from '../services/posts'
import PostContainer from './PostContainer'
import LoadingComponent from './util/LoadingComponent'

import { useDocumentTitle } from '../hooks/useDocumentTitle'
import { MouseEvent } from 'react';
import { all } from 'axios'


interface TagProps {
  tags: Tag[]
}

export type TagRef = {
  getTags: () => { [key: string]: number }
}


const BlogSearchBarTags: ForwardRefExoticComponent<RefAttributes<TagRef>> = forwardRef<TagRef>((_, ref) => {

  const [tagContents, setTagContents] = useState<Tag[]>([])
  const [selectedTags, setSelectedTags] = useState<{ [key: string]: number }>({ 'all': 1 })

  useEffect(() => {
    let subscribed = true;
    postService.getTags()
      .then((response: TagResponse) => {
        if (subscribed) {
          setTagContents(
            response.tags.map((tag: Tag) => ({
              "id": tag.id,
              "slug": tag.slug,
              "name": tag.name,
              "description": tag.description,
              "accent_color": tag.accent_color
            }))
          )
        }
      })
      .catch((err) => {
        console.log(err);
      })

    return () => {
      subscribed = false;
    }
  }, [])

  function tagClicked(event: React.MouseEvent<HTMLDivElement>) {
    if (event.target instanceof Element) {
      let slug: string = event.target.getAttribute('id') || 'none';

      setSelectedTags(currentTags => {
        if (slug == 'all') {
          let newTags = { 'all': 1 }
          return newTags;
        }
        else if (slug in currentTags) {
          let { ...newTags } = currentTags;
          delete newTags[slug];
          delete newTags['all'];
          return newTags;
        } else {
          let { ...newTags } = currentTags;
          newTags[slug] = 1;
          delete newTags['all'];
          return newTags;
        }
      })

    }
  }

  const getTags = () => {
    return selectedTags;
  }

  useImperativeHandle(ref, () => ({
    getTags: () => {
      return getTags();
    }
  }));

  return (
    <div className='blog-search-tags'>
      <div key='all' id='all' className='blog-search-tags__tag' style={{ backgroundColor: 'black', opacity: 'all' in selectedTags ? '1' : '.5' }} onClick={tagClicked}>
        All
      </div>
      {tagContents.map((tag: Tag) => (
        <div key={tag.slug}
          id={tag.slug}
          className='blog-search-tags__tag'
          style={{ backgroundColor: tag.accent_color, opacity: tag.slug in selectedTags ? '1' : '.5' }}
          onClick={tagClicked}>
          {tag.name}
        </div>
      ))}
    </div>
  )
})


type SearchParams = {
  count: number,
  query: string | null,
  tags: string[] | null
}

//TODO: Update search to use url query parameters so state is maintained on navigation.


const BlogPage: React.FC = (): JSX.Element => {

  let postCount = 2;
  const iconSize = '30px'

  const [posts, setPosts] = useState<Post[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [meta, setMeta] = useState<PostMetadata>();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState<SearchParams>({ count: postCount, query: null, tags: null })

  const tagRef = useRef<TagRef>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useDocumentTitle("Blog");
  

  useEffect(() => {
    let subscribed = true;
    postService
      .getPostsBySearch(searchParams.count, page, searchParams.query, searchParams.tags)
      .then((response: PostResponse) => {
        if (subscribed) {
          setPosts(
            response.posts.map((post: Post) => ({
              "slug": post.slug,
              "id": post.id,
              "title": post.title,
              "custom_excerpt": post.custom_excerpt,
              "feature_image": post.feature_image,
              "feature_image_alt": post.feature_image_alt,
              "published_at": new Date(post.published_at),
              "tags": post.tags
            }))
          )
          setMeta(response.meta);
          setLoaded(true);
        }
      })
      .catch((err) => {
        console.error(err);
      })

    return () => {
      subscribed = false;
    }
  }, [searchParams, page])

  const changePage = (type: string) => {

    setPage((current) => {
      switch (type) {
        case 'first':
          return 1;
        case 'prev':
          return current - 1;
        case 'next':
          return current + 1;
        case 'last':
          return meta?.pages || 1;
        default:
          return current;
      }
    })

    window.scrollTo(0,0);
  }


  const searchPosts = () => {

    let query = inputRef.current?.value || null;
    let tags = tagRef.current?.getTags() || null;
    let tagParam: string[] | null = null;

    if (tags) {
      if ('all' in tags)
        tagParam = null;
      else
        tagParam = Object.keys(tags).sort();
    }

    // if params have not changed, do not search
    if (postCount == searchParams.count && query == searchParams.query && JSON.stringify(tagParam) == JSON.stringify(searchParams.tags)) 
      return
    else {
      setPage(1);
      setLoaded(false);
      setSearchParams({
        count: postCount,
        query: query,
        tags: tagParam
      })
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key !== "Enter") return;
    
    searchPosts();
    e.preventDefault();
  }

  return (
    <div className='blog'>
      <div className='blog-search'>
        <div className='blog-search-query'>
          <input className='blog-search__input' id='search-bar' ref={inputRef} type='text' name='post search bar' placeholder='Search here...' onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(e)}/>
          <LuSearch className='blog-search__icon' id='search-bar-submit' size='24px' onClick={searchPosts} />
        </div>
        <BlogSearchBarTags ref={tagRef}/>
      </div>
      {loaded ? (
        <React.Fragment>
          <PostContainer posts={posts} />
          <div className='blog-pageselect-box'>
            {meta?.prev ? <div className='blog-pageselect-left'>
              <LuArrowLeftToLine className='blog-pageselect-icon' size={iconSize} onClick={() => changePage('first')} />
              <LuArrowLeft className='blog-pageselect-icon' size={iconSize} onClick={() => changePage('prev')} />
            </div>
              : <div/>}
            {posts.length ? (
              <div className='blog-pageselect-middle'>
                <span style={{ marginInline: '1rem' }}>Page [{meta?.page}/{meta?.pages}]</span>
              </div>
            ) : <div/>}
            {meta?.next ?
              <div className='blog-pageselect-right'>
                <LuArrowRight className='blog-pageselect-icon' size={iconSize} onClick={() => changePage('next')} />
                <LuArrowRightToLine className='blog-pageselect-icon' size={iconSize} onClick={() => changePage('last')} />
              </div>
              : <div/>}
          </div>
          {(posts.length && meta) ? (
            <span className='blog-meta-text'> {posts.length > 1 ? `Posts ${(meta.page * meta.limit) - meta.limit + 1} - ${(meta.page * meta.limit) + (posts.length - meta.limit)} of total ${meta.total}`
                                                                : `Post ${(meta.page * meta.limit) - 1} of total ${meta.total}`} </span>
          ) : null}
        </React.Fragment>
       )
         : (
           <LoadingComponent/>
         )}
    </div>

  )
}

export default BlogPage;