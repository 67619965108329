
import '../styles/about.css'

import downloadIcon from '../img/download.webp'

import { useDocumentTitle } from '../hooks/useDocumentTitle'

const AboutPage: React.FC = (): JSX.Element => {

  useDocumentTitle("About")

  return (
    <div className="about">
      <div className="about-resume">
        <div className="about-resume__header">
          <h3 style={{margin: "0px"}}>smarkey_resume.pdf</h3>
          <a id="download-img" href="./smarkey_resume.pdf" download>
            <img src={downloadIcon} width="30px" height="30px"/>
          </a>  
        </div>
          <iframe id="resume-iframe" src="./smarkey_resume.pdf"/>
      </div>
    </div>
  )

}

export default AboutPage;