
import '../styles/header.css'
import { Link } from 'react-router-dom';

import nametagImage from '../img/favicon.webp'

// header, including navbar and other persistent features of site

const Header: React.FC = (): JSX.Element => {

  return (
    <div className='header'>
      <div className='header-container'>
        <div className="nametag">
          <Link to="/">
            <img className="nametag-img" alt="homepage link img" src={nametagImage} />
          </Link>
          <h3 className="nametag-text">smarkey.me</h3>
        </div>
        <div className="navbar">
          <Link to="/" className='navbar-item'><span>home</span></Link>
          <Link to="/blog" className='navbar-item'><span>blog</span></Link>
          <Link to="/projects" className='navbar-item'><span>projects</span></Link>
          <Link to="/contact" className='navbar-item'><span>contact</span></Link>
        </div>
      </div>
    </div>
  )

}

export default Header
