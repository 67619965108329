
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import '../styles/projects.css'

import wmucImg from '../img/wmuc_icon.webp'
import siteImg from '../img/favicon_alt.webp'
import pytorchImg from '../img/pytorch.webp'
import nycImg from '../img/nyc.webp'
import pmakerImg from '../img/pmaker.webp'
import covidImg from '../img/covid.webp'

import ProjectItemFeatured from './ProjectItemFeatured';


const ProjectPage: React.FC = (): JSX.Element => {

  useDocumentTitle("Projects")

  return (
    <div className='project'>
      <h3 className='project-header'>
        Website Development
      </h3>
      <ProjectItemFeatured
        title="WMUC Radio Station Website"
        img={wmucImg}
        alt="minimalistic wmuc logo. two slanted and dashed vertical lines, one black and one red. "
        urls={['https://wmuc.umd.edu/', 'https://gitlab.com/monsarkey']}
        desc="This is the website for the College Park Maryland based radio station WMUC, found on 90.5FM. I lead a small team of volunteer developers, and was the main contributor to its frontend and backend development, though mostly frontend."
        techs="React.js, JavaScript, HTML, CSS, Git, Node.js">
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
        </div>
      </ProjectItemFeatured>
      <ProjectItemFeatured
        title="This Website!"
        img={siteImg}
        alt="minimalistic depiction of a sapling drawn in negative space, surrounded by an orange circle"
        urls={['https://github.com/monsarkey/personal-site']}
        desc="This is my current personal website, built from scratch using React and TypeScript. Expand if you want to read more about its development, or click the Github link to view its source code."
        techs="React.js, TypeScript, HTML, CSS, Git, Node.js">
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
        </div>
      </ProjectItemFeatured>
      <h3 className='project-header'>
        Data Analysis
      </h3>
      <ProjectItemFeatured
        title="Sleep Stage Classificiation Project"
        img={pytorchImg}
        alt="pytorch logo (minimalistic depiction of a flame over a white background)"
        urls={['https://github.com/monsarkey/sleep_categorization']}
        desc="The main project that I worked on during my time with Origin Wireless AI, focused on the use of respiratory data gathered via WiFi. It involved exploratory data analysis to determine the level of differentiability between human sleep stages (deep sleep, light sleep, REM sleep, and awake), leading to the creation of an LSTM model to differentiate along the most important axes."
        techs="Python, PyTorch, Keras">
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
        </div>
      </ProjectItemFeatured>
      <ProjectItemFeatured
        title="NYC Crime Data Analysis Project"
        img={nycImg}
        alt="map view of new york city, layered with a heatmap showcasing density of crime around the city"
        urls={['https://monsarkey.github.io/', 'https://github.com/monsarkey/monsarkey.github.io']}
        desc="A project analyzing city-wide NYPD crime data, delving into environmental factors affecting crime across its five boroughs. Includes data visualization comparing and contrasting crime statistics between boroughs, discussing underlying material factors, and tests the efficacy of decision trees on predicting future crime based on these factors."
        techs="Python, Jupyter Notebook, Github">
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
        </div>
      </ProjectItemFeatured>
      <ProjectItemFeatured
        title="Covid Stock Correlation Project"
        img={covidImg}
        alt="plot with 3 data series, two line graphs featuring continuous stock data, and a scatter plot showing daily covid cases"
        urls={['https://github.com/monsarkey/covid_stock_correlation']}
        desc="A collaborative project aimed at analyzing the effects of COVID-19 on different sectors of the American economy. Used statistics to interpret relationships between rising Covid cases and performance in these sectors, determining which were most and least impacted by the virus."
        techs="R, RStudio">
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
        </div>
      </ProjectItemFeatured>
      <h3 className='project-header'>
        Software Development
      </h3>
      <ProjectItemFeatured
        title="Spotify Playlist-Maker Application"
        img={pmakerImg}
        alt="minimalistic depiction of a hammer drawn in negative space, surrounded by a red-orange circle"
        urls={['https://github.com/monsarkey/playlist-maker']}
        desc="An application I built in Python that automates the process of designing client-personalized Spotify playlists. Using a GUI I designed in PyQt5, it receives user specifications, then communicates with Spotify’s API to generate desired song recommendations and compile them into a new playlist on your account."
        techs="Python, PyQt5, Spotify API">
        <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
          in voluptate velit esse cillum dolore eu fugiat nulla pariatur
        </div>
      </ProjectItemFeatured>
    </div>
  )

}

export default ProjectPage;