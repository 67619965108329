
import '../styles/body.css'

interface BodyProps {
  children: React.ReactNode
};

const Body: React.FC<BodyProps> = ({ children }: BodyProps) => {

  return (
    <div className='body'>
      {children}
    </div>
  )

}

export default Body;