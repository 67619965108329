
import '../styles/contact.css'

import React from 'react';
import ReactDOM from 'react-dom';
import { SocialIcon } from 'react-social-icons';


interface ContactType {
    children?: React.ReactNode
    url: string
    img?: string
    alt?: string
};

const ContactItem:React.FC<ContactType> = ({children, url, img, alt} : ContactType): JSX.Element => {

    const size = 100;

    return (
      <div className='contact-item'>
          <div className='contact-pic'>
            {!img ? <SocialIcon url={url} style={{height: size, width: size}}/> 
              : <a href={url}><img src={img} width={size} height={size} alt={alt}></img></a>}
          </div>
          <div className='contact-body__desc'>
            {children ? children : null}
          </div>
      </div>
    )
  
}

export default ContactItem;