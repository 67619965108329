
import '../styles/projects.css'
import { SocialIcon } from 'react-social-icons';
import { MouseEvent, useState } from 'react';

interface ProjectProps {
  children: React.ReactNode
  title: string
  desc: string
  techs: string
  img?: string
  alt?: string
  urls: string[]
}

const ProjectItemFeatured: React.FC<ProjectProps> = ({ children, title, desc, techs, img, alt, urls }: ProjectProps): JSX.Element => {

  var imgSize = 140;
  const linkSize = 25;

  // const [isExpanded, setIsExpanded] = useState(false);

  // const handleClick = (event: MouseEvent) => {

  //   // setIsExpanded(!isExpanded)
  //   setIsExpanded(false)

  // }

  return (
    <div className='project-item--featured'>
      <div className='project-item--featured-body' style={{ height: "auto" }}>
        {img ? <img className='project-img' width={imgSize} height={imgSize} src={img} alt={alt}/> : <div />}
        <div className='project-body' style={{ minHeight: `${imgSize}px` }}>
          <div className='project-body__title'>
            <div style={{ fontFamily: "Viga", paddingRight: "6px" }}>{title}</div>
            <div>
              {urls.map((url, i) =>
                <SocialIcon key={i} className='project-body__link' url={url} style={{ height: linkSize, width: linkSize, marginRight: "3px" }} />)}
            </div>
          </div>
          <div className='project-body__desc'>
            {/* {isExpanded ? <div>{children}</div> : */}
             <div>{desc}</div>
          </div>
          <div className='project-body__techs'>Technologies: {techs} </div>
        </div>
      </div>
      {/* <div className='project-body__expand' onClick={handleClick}>
        {isExpanded ? <div>less ^^</div>
          : <div>more <span style={{fontWeight: 'normal'}}>v v</span></div>}
      </div> */}
    </div>
  )

}

export default ProjectItemFeatured;