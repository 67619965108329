
import '../styles/misc.css'
import { useDocumentTitle } from '../hooks/useDocumentTitle'


const ErrorPage: React.FC = (): JSX.Element => {

    useDocumentTitle("Error")

    return (
        <div className='error'>
            <div className='error-msg-container'>
                <h2 className='error-msg'>
                    page not found :/
                </h2>
            </div>
        </div>
    )

}

export default ErrorPage;