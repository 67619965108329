import React from 'react';
import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// import './styles/app.css';

// static elements on webpage
import Container from './components/Container';
import Body from './components/Body';
import Header from './components/Header';
import Footer from './components/Footer';

// the main pages through which we navigate
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ProjectPage from './components/ProjectPage';
import ContactPage from './components/ContactPage';
import BlogPage from './components/BlogPage';
import PostPage from './components/PostPage';
import ErrorPage from './components/ErrorPage';
import { useDocumentTitle } from './hooks/useDocumentTitle';

import './styles/app.css';


const App: React.FC = (): JSX.Element => {

  useDocumentTitle();
  
  return (
    <BrowserRouter>
      <Header/>
      <Container>  
        <Body>
          <Routes>
            <Route path="/" element={<HomePage />}/>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/projects" element={<ProjectPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path={'/blog/:slug' as const} element={<PostPage/>} />
            <Route path="*" element={<ErrorPage />}/>
            {/* <Route path='/policy' element={(useDocumentTitle("Policy"), <div>haven't written this yet</div>)} /> */}
            {/* <Route path='/credits' element={(useDocumentTitle("Credits"), <div>TODO</div>)} /> */}
          </Routes>
        </Body>
      </Container>
      <Footer/>
    </BrowserRouter>
  );

}

export default App;