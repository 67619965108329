
import { useEffect } from "react";

export const useDocumentTitle : Function = (title: string) : void => {

    if (title) {
        document.title = title? `${title} | smarkey.me` : "smarkey.me";
    }

    return;
}