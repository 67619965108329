
import '../styles/footer.css'
import SVGTest from '../img/chickadee.svg'
import rymImage from '../img/rym.webp'
import lastfmImage from '../img/lastfm.webp'
import letterboxdImage from '../img/letterboxd.webp'

import ContactItem from './ContactItem'
import { SocialIcon } from 'react-social-icons'
import React, { FormEventHandler } from 'react'
import { useState, useMemo } from 'react'


// static footer, including extra links

const Footer: React.FC = (): JSX.Element => {

  const [emailInput, setEmailInput] = useState("")
  const initYear = 2023;

  const iconSize = "28px";

  function getCopyright() {

    var currYear = (new Date()).getFullYear()
    var yearStr = initYear + (currYear != initYear ? "-" + currYear : "")
  
    return yearStr;

  }

  const cachedCopyright = useMemo(getCopyright, []);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {

    // setEmailInput(e.target.value)
  
  }

  /* not doing anything with this yet */
  function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
    
    e.preventDefault();

    // timer = setTimeout(() => alert('hellow'), 10);

    console.log(emailInput);
    
  }

  return (
    <div className='footer'>
      <div className='footer-container'>
        <div className='footer-flexbox'>
          <img className='footer-flexbox__art' src={SVGTest} alt="vector art of a perched chickadee in monochrome orange" width="180px"></img>
          <div className='footer-flexbox-container'>
            <h1 style={{marginTop: "0", marginBottom: "0", textAlign: "center"}}>Thank you for visiting!</h1>
            <div className='footer-flexbox-text'>
              <div className='footer-flexbox-text__socials'>
                <h2 style={{marginTop: "0", marginBottom: "4px", fontSize: "1em"}}>Socials</h2>
                <ul style={{marginTop: "5px", marginBottom: "0", paddingLeft: "0"}}>
                  <li><SocialIcon url="https://github.com/monsarkey" style={{width: iconSize, height: iconSize}}/></li>
                  <li><SocialIcon url="https://open.spotify.com/user/derpbaby?si=8584104dc7f94c86" style={{width: iconSize, height: iconSize}}/></li>
                  <li><a href="https://rateyourmusic.com/~smarkey" style={{display: "inline-block", width: iconSize, height: iconSize, position: "relative", overflow: "hidden", verticalAlign: "middle"}}><img src={rymImage} alt="RateYourMusic logo" width={iconSize} height={iconSize}></img></a></li>
                  <li><a href="https://www.last.fm/user/mon_sarkey" style={{display: "inline-block", width: iconSize, height: iconSize, position: "relative", overflow: "hidden", verticalAlign: "middle"}}><img src={lastfmImage} alt="LastFM logo" width={iconSize} height={iconSize}></img></a></li>
                  <li><a href="https://letterboxd.com/mon_sarkey/" style={{display: "inline-block", width: iconSize, height: iconSize, position: "relative", overflow: "hidden", verticalAlign: "middle"}}><img src={letterboxdImage} alt="Letterboxd logo" width={iconSize} height={iconSize}></img></a></li>
                </ul>
              </div>
              <div className='footer-flexbox-text__links'>
                <h2 style={{marginTop: "0", marginBottom: "4px", textAlign: "center", fontSize: "1em"}}>Other Links</h2>
                <div id='link-block'>
                  <a href='/credits'><span style={{fontWeight: "bold"}}>Credits</span></a>
                  <a href='/https://github.com/monsarkey/personal-site'><span style={{fontWeight: "bold"}}>Source Code</span></a>
                  <a href='/contact'><span style={{fontWeight: "bold"}}>Contact Me</span></a>
                  <a href='/policy'><span style={{fontWeight: "bold"}}>Privacy Policy</span></a>
                </div>
              </div>
              <div className='footer-flexbox-text__newsletter'>
                <h2 style={{marginTop: "0", marginBottom: "4px", fontSize: "1em"}}>Sign up</h2>
                <form onSubmit={handleSubmit}>
                  <input id='newsletter-input' autoComplete='email' name="emailInput" placeholder="Your email here" pattern="[a-zA-Z]*[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$" onChange={handleChange} required/>
                  <button id='newsletter-button' type='submit'>SUBMIT</button>
                </form>
                <p style={{marginTop: ".4rem", marginBottom: "0", fontSize: "1.2rem"}}>Enter your email if you'd like to receive updates!</p>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-copyright' style={{textAlign: "center", paddingTop: "2rem"}}>
          ©{cachedCopyright}. All Rights Reserved.
        </div>
      </div>
    </div>
  )
}

export default Footer
