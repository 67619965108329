import React from 'react'
import {Post, Tag} from '../types'

import { writeDateString } from '../util'
import ImageContainer from './ImageContainer'
import { Link } from 'react-router-dom'


interface PostContainerProps {
  posts: Post[]
}


const PostContainer: React.FC<PostContainerProps> = ({posts}: PostContainerProps): JSX.Element => {


  return (
    <div className='post-container'>
      {posts.length ? ( posts.map((post: Post) => (
        <Link key={post.slug} to={`/blog/${post.slug}`} reloadDocument style={{marginBlock: '1rem'}}>
          <div className='post-container-post'>
            <ImageContainer img={post.feature_image} alt={post.feature_image_alt} size={140}/>
            <div className='post-container-post-body'>
              <h4>{post.title}</h4>
              {/* <div className='post-container-post-body-meta'> */}
                <span className='post-container-post-body__date'>{writeDateString(post.published_at)}{/*<span style={{marginInline: '.6rem'}}>•</span>*/}</span>
                <div className='post-container-post-body__taglist'>
                  {post.tags?.map((tag: Tag) => (
                    <div key={tag.slug} className='post-container-post-body__tag' style={{backgroundColor: tag.accent_color}}>{tag.name}</div>
                  ))}
                </div>
              {/* </div> */}
              <span className='post-container-post-body__desc'>{post.custom_excerpt}</span>
            </div>
          </div>
        </Link>
      ))) : ( 
        <div className='post-container-error'>
          <h2>no posts found :/</h2>
        </div>
      )}
    </div>
  )
}

export default PostContainer;