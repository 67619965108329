
import '../../styles/misc.css'
import loadingWheelSVG from '../../img/loading.svg';


const LoadingComponent: React.FC = (): JSX.Element => {

  // LOADING SVG FROM https://github.com/n3r4zzurr0/svg-spinners/blob/main/svg-css/ring-resize.svg

  return (
    <div className='loading'>
      <div className='loading-svg-container'/>
      <h3 className='loading__text'>Loading...</h3>
    </div>
  )

}

export default LoadingComponent;