
import '../styles/image.css'
import { useState } from 'react'


interface ImageProps {
  img: string
  alt: string
  size: number
  height?: number
  children?: React.ReactNode
}


const ImageContainer: React.FC<ImageProps> = ({ img, alt, size, height, children }: ImageProps): JSX.Element => {

  const [isLoaded, setIsLoaded] = useState(false);

  function onLoad(event: React.SyntheticEvent<HTMLImageElement, Event>) {
    if (!isLoaded) {
      setIsLoaded(true);
    }
    if (event.target instanceof HTMLImageElement) {

      // taller than it is wide
      if (event.target.naturalWidth < event.target.naturalHeight) {
        if (event.target.naturalHeight < size) { // if image is too small, stretch to reach bounds while keeping aspect ratio
          event.target.height = size;
          event.target.width = event.target.width + ((size - event.target.naturalHeight) * (event.target.naturalWidth / event.target.naturalHeight))
        }
      } // wider than it is tall
      else if (event.target.naturalWidth > event.target.naturalHeight) {
        if (event.target.naturalWidth < size) { // if image is too small, stretch to reach bounds while keeping aspect ratio
          event.target.width = size;
          event.target.height = event.target.height + ((size - event.target.naturalWidth) * (event.target.naturalHeight / event.target.naturalWidth))
        }
      }
    }
  }

  return (
    <div className='image' style={{ minWidth: size, minHeight: size || height, maxHeight: size }} >
      <div className='image-container' style={{ minHeight: size || height }}>
        <div style={{ display: isLoaded ? "none" : "block" }}>
        </div>
        <img src={img}
          alt={alt}
          style={{ display: isLoaded ? "block" : "none", maxWidth: size, maxHeight: size }}
          onLoad={onLoad}
        />
      </div>
      {/* <div className='image-desc'>
                <i>
                    {children}
                </i>
            </div> */}
    </div>
  )

}

export default ImageContainer;